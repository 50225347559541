import * as styles from './PageHeader.module.css';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';

import MarkdownIt from 'markdown-it';
import defaultBackground from '../../images/warehouse.png';
import { setTrue } from '../../state/slices';
import { useDispatch } from 'react-redux';
import CTAbutton from '../CTAbutton/CTAbutton';

const decodeEntities = encodedString => {
  var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
  var translate = {
      "nbsp":" ",
      "amp" : "&",
      "quot": "\"",
      "lt"  : "<",
      "gt"  : ">"
  };
  return encodedString.replace(translate_re, function(match, entity) {
      return translate[entity];
  }).replace(/&#(\d+);/gi, function(match, numStr) {
      var num = parseInt(numStr, 10);
      return String.fromCharCode(num);
  });
}

const md = new MarkdownIt();

const PageHeader = (props) => {
  const { title, header } = props;
  const dispatch = useDispatch();

  const clickHandler = (e) => {
    e.preventDefault();
    switch (e.target.attributes['js-name'].nodeValue.split(':')[1]){
      case 'display-alert':
        alert('this is a display alert');
        break;
      case 'contact-form':
        dispatch(setTrue());
        break;
      default:
        console.warn('Click Handler not defined in Strapi define using javascript:click-handler-name')
    }
  };

  if (header && header.VideoBackground) {
    return (
      <div className={styles.Cover}>
        <video autoPlay muted loop id='myVideo' className={styles.Video}>
          <source src={header.Background.url} type='video/mp4' />
        </video>
        <div className={styles.CoverContent}>
          {header ? (
            <>
              <h1>{title}</h1>
              {header.Content && (
                <div
                  className={styles.Content}
                  dangerouslySetInnerHTML={{
                    __html: decodeEntities(md.render(header.Content)),
                  }}
                />
              )}
              {header.CTA && (
                  <div
                    className={styles.Content}
                    id={styles.cta}
                    //onClick={(e) => clickHandler(e)}
                    dangerouslySetInnerHTML={{ __html: decodeEntities(md.render(header.CTA)) }}
                  />
                )}
              {!!header.CTAButtonText && !!header.CTAButtonLink ? (
                <CTAbutton url={header.CTAButtonLink} style={{margin:'auto', cursor:'pointer'}} message={header.CTAButtonText} handler={(e) => clickHandler(e)} />
              ) : null}
            </>
          ) : (
            <h1>No content available</h1>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <section>
        <div className={styles.Cover}>
          <GatsbyImage
            loading='eager'
            className={styles.BackgroundImage}
            image={getImage(header.Background.localFile)}></GatsbyImage>
          <div className={styles.CoverContent}>
            {header ? (
              <>
                <h1>{title}</h1>
                {header.Content && (
                  <div
                    className={styles.Content}
                    dangerouslySetInnerHTML={{
                      __html: decodeEntities(md.render(header.Content)),
                    }}
                  />
                )}
                {header.CTA && (
                  <div
                    className={styles.Content}
                    id={styles.cta}
                    //onClick={(e) => clickHandler(e)}
                    dangerouslySetInnerHTML={{ __html: decodeEntities(md.render(header.CTA)) }}
                  />
                )}
                {!!header.CTAButtonText && !!header.CTAButtonLink ? (
                  <CTAbutton url={header.CTAButtonLink} style={{margin:'auto',cursor:'pointer'}} message={header.CTAButtonText} handler={(e) => clickHandler(e)} />
                ) : null}
              </>
            ) : (
              <h1>No content available</h1>
            )}
          </div>
        </div>
      </section>
    );
  }
};

export default PageHeader;
