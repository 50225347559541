import { Path, Spinner, SpinnerWrapper } from './Loading.module.css';

import React from 'react';

const Loading = (props) => {
  return (
    <div className={SpinnerWrapper}>
      <svg className={Spinner} viewBox='0 0 50 50'>
        <circle
          className={Path}
          cx='25'
          cy='25'
          r='20'
          fill='none'
          stroke-width='5'></circle>
      </svg>
    </div>
  );
};

export default Loading;
