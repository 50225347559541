import {
  ButtonLink,
  ButtonRow,
  Content,
  JobList,
  JobListing,
  Title,
} from '../../styles/careers.module.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../../containers/layout';
import Loading from '../../components/UI/Loading/Loading';
import PageHeader from '../../components/PageHeader/PageHeader';
import PageHeader2 from '../../components/PageHeader/PageHeader2';
import careers from '../../images/Careers-Page.jpg';

const Careers = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [nodes, setNodes] = useState();

  const getData = async () => {
    const response = await fetch(
      'https://v3distribution.applicantpro.com/feeds/jobs_by_domain.xml'
    );
    const response2 = await fetch(
      'https://vpegroup.applicantpro.com/feeds/jobs_by_domain.xml'
    );

    const data = await response.text();
    const data2 = await response2.text();
    const parser = new DOMParser();

    const dom = parser.parseFromString(data, 'text/xml');
    const dom2 = parser.parseFromString(data2, 'text/xml');
    const jobs = dom.getElementsByTagName('job');
    const jobs2 = dom2.getElementsByTagName('job');
    const jobsArr = [...jobs, ...jobs2];

    //   dataObj[i] = {
    //     title: el.children[0].textContent,
    //     url: el.children[5].textContent,
    //     description: el.children[13].textContent,
    //     date: new Date(el.children[22].textContent),
    //     // employmentType: el.children[15].textContent,
    //     // department: el.children[18].textContent,
    //     // company: el.children[7].textContent,
    //     // city: el.children[8].textContent,
    //     // startDate: el.children[1].textContent,
    //   };

    const dataObj = jobsArr.map((el, i) => {
      let obj = {
        title: el.children[0].textContent,
        url: el.children[5].textContent,
        description: el.children[13].textContent,
        date: new Date(el.children[22].textContent),
      };
      return obj;
    });
    dataObj.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });

    const newDomElements = dataObj.map((el, i) => {
      return (
        <div key={i} className={JobListing}>
          <center>
            <h4>{el.title}</h4>
          </center>
          <p>{el.description.split('.', 2).join('.')}.</p>
          <div className={ButtonRow}>
            <a
              className={ButtonLink}
              href={el.url}
              rel='noreferrer'
              target='_blank'>
              Apply
            </a>
          </div>
        </div>
      );
    });

    setNodes(newDomElements);

    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  const careersQuery = useStaticQuery(graphql`
    query careersQuery {
      strapiSiteImages {
        Careers {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
      }
      strapiStaticPages(Title: { eq: "Careers" }) {
        Title
        SEO {
          MetaTitle
          MetaDescription
          Keywords
          PreviewImage {
            formats {
              small {
                url
                width
                height
              }
            }
          }
        }
      }
    }
  `);
  const image = careersQuery.strapiSiteImages.Careers.localFile;

  const seoData = careersQuery.strapiStaticPages.SEO;

  const header = {
    VideoBackground: false,
    Background: {
      localFile: {
        ...image,
      },
    },
    Content:
      "We're adopting - come join the V3 fam! We say family, because that's what it feels like when you work here. We collaborate, challenge each other, and have fun together. Think you'd be a good fit? We want to hear from you!",
  };

  return (
    <Layout location={props.location} noPadding seoData={seoData}>
      <PageHeader2 title={'Careers'} header={header} />
      <div className={Content}>
        <center>
          <h3 className={Title}>Open Positions</h3>
        </center>
        {isLoading ? <Loading /> : <div id={JobList}>{nodes}</div>}
      </div>
    </Layout>
  );
};

export default Careers;
