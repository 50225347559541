import * as styles from './PageHeader.module.css';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';

import MarkdownIt from 'markdown-it';
import defaultBackground from '../../images/warehouse.png';
import { setTrue } from '../../state/slices';
import { useDispatch } from 'react-redux';

const md = new MarkdownIt();

const PageHeader = (props) => {
  const { title, header } = props;
  const [background, setBackground] = useState(null);
  const dispatch = useDispatch();

  const clickHandler = (e) => {
    e.preventDefault();
    dispatch(setTrue());
  };

  useEffect(() => {
    if (header && header.VideoBackground !== true) {
      const { Background } = header;

      const backgroundImg = {
        url: Background ? Background.url : null,
        smallUrl:
          Background && Background.formats !== null
            ? Background.formats.medium.url
            : null,
        name: Background ? Background.name : null,
      };
      setBackground({
        background: `linear-gradient(rgba(26, 26, 26, 1) 21%, rgba(26, 26, 26, 0.5) 100%), url(${
          header.Background !== null
            ? backgroundImg.smallUrl
            : defaultBackground
        }) center/cover no-repeat`,
      });
    } else if (header && header.VideoBackground) {
      setBackground(null);
    }
  }, [header]);

  if (header && header.VideoBackground) {
    return (
      <div className={styles.Cover} style={background}>
        <div>test</div>
        <video autoPlay muted loop id='myVideo' className={styles.Video}>
          <source src={header.Background.url} type='video/mp4' />
        </video>
        <div className={styles.CoverContent}>
          {header ? (
            <>
              <h1>{title}</h1>
              {header.Content && (
                <div
                  className={styles.Content}
                  dangerouslySetInnerHTML={{
                    __html: md.render(header.Content),
                  }}
                />
              )}
              {header.CTA && (
                <div
                  className={styles.Content}
                  id={styles.cta}
                  onClick={(e) => clickHandler(e)}
                  dangerouslySetInnerHTML={{ __html: md.render(header.CTA) }}
                />
              )}
            </>
          ) : (
            <h1>No content available</h1>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <section>
        <div>test</div>
        <div className={styles.Cover} style={background}>
          <GatsbyImage image={getImage(header.Background.localFile)} />

          <div className={styles.CoverContent}>
            {header ? (
              <>
                <h1>{title}</h1>
                {header.Content && (
                  <div
                    className={styles.Content}
                    dangerouslySetInnerHTML={{
                      __html: md.render(header.Content),
                    }}
                  />
                )}
                {header.CTA && (
                  <div
                    className={styles.Content}
                    id={styles.cta}
                    onClick={(e) => clickHandler(e)}
                    dangerouslySetInnerHTML={{ __html: md.render(header.CTA) }}
                  />
                )}
              </>
            ) : (
              <h1>No content available</h1>
            )}
          </div>
        </div>
      </section>
    );
  }
};

export default PageHeader;
